/* BOTTON THEME */
.switch {
    display: flex;
    align-self: center;
    height: 1.5vw;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    display: flex;
    cursor: pointer;
    border-radius: 34px;
    background-color: #2196F3;
    transition: .4s;
    width: 3.5vw;
    height: 1.5vw;
    align-items: center;
  }
  .slider:before {
    content: "";
    height: 1vw;
    width: 1vw;
    border-radius: 50%;
    background-color: white;
    margin-left: 0.25vw;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: #ccc;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  input:checked + .slider:before {
    transform: translateX(2vw);
  }
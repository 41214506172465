*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  max-width: 100vw;
}
.skills-container{
  display: grid;
  grid-template-columns: repeat(2,minmax(0,1fr));
  gap: 1rem;
}

@media only screen and (min-width: 800px) {
  .skills-container{
    display: grid;
    grid-template-columns: repeat(5,minmax(0,1fr));
  }
}